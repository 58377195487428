import { openAlert } from "components/dialog";

import { buyServerThunks, buyThunks } from "store/buy";

import {
  loadCoinsByIds,
  loadAlsoTradingCoins,
  loadCoin,
  loadCoins,
  loadPrices,
  toggleFavorite,
} from "./thunks";
import { loadCategoriesServer, loadCoinServer } from "./serverThunks";
import { setCoinEntities } from "./duck/operations";
import { CoinsBuilder } from "./duck/types";

const extraReducers = (builder: CoinsBuilder) => {
  //#region loadCoinServer

  builder
    .addCase(loadCoinServer.pending, state => {
      state.coinDetailsError = null;
      state.isCoinLoading = true;
    })
    .addCase(loadCoinServer.fulfilled, (state, action) => {
      state.isCoinLoading = false;
      state.entities[action.payload.letterId] = action.payload;
    })
    .addCase(loadCoinServer.rejected, (state, action) => {
      state.isCoinLoading = false;
      state.coinDetailsError = action.error.message;
    });

  //#endregion

  //#region loadCategories

  builder
    .addCase(loadCategoriesServer.pending, state => {
      state.isLoadedCategories = false;
    })
    .addCase(loadCategoriesServer.fulfilled, (state, { payload }) => {
      state.isLoadedCategories = true;
      state.categories = payload;
    });

  //#endregion

  //#region loadCoins

  builder
    .addCase(loadCoins.pending, (state, action) => {
      state.coinsError = null;
      state.loadMoreError = null;
      state.isLoading = true;
      const { category, search, sort, isLoadMore } = action.meta.arg;
      if (!isLoadMore) {
        state.ids = [];
      }

      state.loadFilter = { category, search, sort };
    })
    .addCase(loadCoins.fulfilled, (state, action) => {
      const entities = { ...state.entities };
      const ids = action.payload.results.map(coin => {
        entities[coin.letterId] = coin;
        return coin.letterId;
      });
      state.ids = [...state.ids, ...ids];
      state.entities = entities;

      state.isLoading = false;
      state.isNextCoins = action.payload.isNextCoins;
    })
    .addCase(loadCoins.rejected, (state, action) => {
      if (action.error.name === "AbortError") {
        return;
      }

      state.isLoading = false;

      if (action.meta.arg.isLoadMore) {
        state.loadMoreError = action.error.message;
        openAlert({ message: "An error occurred while loading the coins" });

        return;
      }

      state.coinsError = action.error.message;
    });

  //#endregion

  //#region loadCoin

  builder
    .addCase(loadCoin.pending, state => {
      state.coinDetailsError = null;
      state.isCoinLoading = true;
    })
    .addCase(loadCoin.fulfilled, (state, action) => {
      state.isCoinLoading = false;
      state.entities[action.meta.arg] = action.payload;
    })
    .addCase(loadCoin.rejected, (state, action) => {
      state.isCoinLoading = false;
      state.coinDetailsError = action.error.message;
    });

  //#endregion

  //#region loadPrices

  builder.addCase(loadPrices.fulfilled, (state, action) => {
    const entities = { ...state.entities };
    for (const {
      cryptocurrencyLetterId,
      percentChange24H,
      price,
    } of action.payload) {
      const coin = state.entities[cryptocurrencyLetterId];
      if (!coin) {
        continue;
      }

      entities[cryptocurrencyLetterId] = {
        ...coin,
        lastMarketQuote: {
          ...coin?.lastMarketQuote,
          price,
          percentChange24H,
        },
      };
    }

    state.entities = entities;
  });

  //#endregion

  //#region toggleFavorite

  builder.addCase(toggleFavorite.pending, (state, action) => {
    state.entities[action.meta.arg].isFavorite =
      !state.entities[action.meta.arg].isFavorite;
  });

  //#endregion

  //#region tradeCoins

  builder.addCase(buyServerThunks.loadCoinServer.fulfilled, (state, action) => {
    state.entities[action.payload.letterId] = action.payload as Coin;
  });

  builder.addCase(buyThunks.loadCoins.fulfilled, setCoinEntities);

  //#endregion

  //#region alsoTradingCoins

  builder.addCase(loadAlsoTradingCoins.fulfilled, setCoinEntities);

  //#endregion

  //#region loadCoinsByIds

  builder.addCase(loadCoinsByIds.fulfilled, setCoinEntities);

  //#endregion
};

export default extraReducers;
